<template>
  <div
    id="app"
    :style="{
      cursor: `url('${imgBasePath}napoleon-pointeur.png'), auto`,
    }"
  >
    <Intro :on="showIntro" />
    <Overlay :class="{ off: !showOverlay }" @close="showOverlay = false" />
    <div
      id="logo-ribbon"
      class="text-main cursor-pointer-custom"
      :class="{ on: hoveringLogo }"
      @mouseenter="hoveringLogo = true"
      @mouseleave="hoveringLogo = false"
    >
      <a
        class="flex items-center"
        href="https://www.institutdefrance.fr/lesdossiers/napoleon-apologie-et-satire-images-dun-regne/"
        target="_blank"
        rel="noopener"
      >
        <IDFLogo :height="48" :width="40" />
        <div>Retour vers le site de<br />l'Institut de France</div>
      </a>
    </div>
    <div id="frame-border-top"></div>
    <div id="frame-border-right"></div>
    <div id="frame-border-bottom"></div>
    <div id="frame-border-left"></div>
    <div class="frame-line-h frame-line-top"></div>
    <div class="frame-line-h frame-line-top-inner"></div>
    <div class="frame-line-h frame-line-bottom"></div>
    <div class="frame-line-h frame-line-bottom-inner"></div>
    <div class="frame-line-v frame-line-right"></div>
    <div class="frame-line-v frame-line-right-inner"></div>
    <div class="frame-line-v frame-sidebar-left"></div>
    <div class="frame-line-v frame-sidebar-left-inner"></div>
    <div id="frame-sm" class="frame">
      <Topbar @show-overlay="showOverlay = true" />
      <SecondarySidebar />
      <div class="frame-content">
        <div
          class="main-content"
          :class="{ 'main-content-no-margin': onCarousel }"
        >
          <div class="transition-overlay"></div>
          <router-view screenSize="sm" />
        </div>
      </div>
    </div>
    <div id="frame-md" class="frame">
      <MainSidebar
        @show-overlay="showOverlay = true"
        :class="{ on: showMdMenu }"
      />
      <SecondarySidebar />
      <div
        id="sidebar-opener"
        class="cursor-pointer-custom"
        @click="$store.dispatch('updateShowMdMenu', { show: !showMdMenu })"
        :class="{ on: showMdMenu }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            :d="showMdMenu ? 'M9 5l7 7-7 7' : 'M15 19l-7-7 7-7'"
          />
        </svg>
      </div>
      <div class="frame-content">
        <div
          class="main-content"
          :class="{ 'main-content-no-margin': onCarousel }"
        >
          <div class="transition-overlay"></div>
          <router-view screenSize="md" />
        </div>
      </div>
    </div>
    <div id="frame-lg" class="frame">
      <div class="frame-content">
        <MainSidebar @show-overlay="showOverlay = true" />
        <SecondarySidebar />
        <div
          class="main-content"
          :class="{ 'main-content-no-margin': onCarousel }"
        >
          <div class="transition-overlay"></div>
          <router-view screenSize="lg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import articles from "@/assets/data/articles.json";

import IDFLogo from "@/components/IDFLogo.vue";
import Intro from "@/components/Intro.vue";
import MainSidebar from "@/components/MainSidebar.vue";
import Overlay from "@/components/Overlay.vue";
import SecondarySidebar from "@/components/SecondarySidebar.vue";
import Topbar from "@/components/Topbar.vue";

export default {
  name: "App",
  components: {
    IDFLogo,
    Intro,
    MainSidebar,
    Overlay,
    SecondarySidebar,
    Topbar,
  },
  data() {
    return {
      showIntro: true,
      showOverlay: true,
      hoveringLogo: false,
    };
  },
  computed: {
    ...mapState({
      showMdMenu: (state) => state.showMdMenu,
    }),
    onCarousel() {
      return !this.$route.params.article;
    },
    imgBasePath() {
      let p = process.env.BASE_URL;
      if (!p.endsWith("/")) p += "/";
      return p;
    },
  },
  async created() {
    this.$store.commit("articles/setArticles", { articles });
    await this.$store.dispatch("updateInitialized", { initialized: true });
  },
  mounted() {
    const $this = this;
    setTimeout(() => {
      $this.showIntro = false;
    }, 3000);
  },
  watch: {
    showMdMenu(willOpen) {
      if (!willOpen) {
        this.$store.dispatch("updateSideText", { text: null });
      }
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "InstitutDeFrance";
  src: url(assets/fonts/institutdefrance-regular.woff2) format("woff2"),
    url(assets/fonts/institutdefrance-regular.woff) format("woff");
}
@font-face {
  font-family: "Graphie";
  src: url(assets/fonts/Graphie-Regular.ttf) format("truetype");
}

.font-graphie {
  font-family: Graphie;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
