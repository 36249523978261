<template>
  <div class="absolute top-0 left-0 w-full h-full">
    <div
      id="carousel-horizontal"
      class="carousel"
      :class="{ 'carousel-dense': isHomeCarousel }"
      @contextmenu.prevent
    >
      <div
        v-for="row in filteredArticlesByRow.order"
        :key="row"
        class="carousel-row"
      >
        <div
          v-for="item in filteredArticlesByRow.items[row]"
          :key="item.id"
          class="carousel-item"
          :class="{ 'carousel-item-overlapping': isHomeCarousel }"
          @mouseenter="() => setHovering(item)"
          @mouseleave="() => setHovering(null)"
        >
          <router-link
            :to="{
              name: 'ArticlePage',
              params: { article: item.id },
            }"
          >
            <img
              :src="`${baseUrl}small-images/${item.image}`"
              class="cursor-pointer-custom"
              :style="{
                height: `calc(${
                  item.rel_surface
                } * (100vh - 2 * var(--frame-padding) - 2 * var(--frame-size) - 64px - ${
                  rows - 1
                } * 16px) / ${rows})`,
              }"
            />
          </router-link>
        </div>
      </div>
    </div>
    <div
      ref="carousel"
      id="carousel-vertical"
      class="carousel"
      :class="{ 'carousel-dense': isHomeCarousel }"
      @contextmenu.prevent
      @scroll="handleScroll"
    >
      <div v-if="isHomeCarousel" key="home-carousel">
        <CarouselTopic
          v-for="topic in topics.topics"
          :key="topic.key"
          :topic="{
            key: topic.key,
            title: topic.name,
            studentDocument: topic.studentDocument,
            count: (articlesByTopic[topic.key] || []).length,
            subtopics: topics.subtopics[topic.key],
          }"
        />
      </div>
      <div v-else key="topic-carousel">
        <div
          class="navigation-button scroll-to-top-btn"
          :class="{ on: showScrollToTop }"
          @click="scrollToTop"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M5 15l7-7 7 7" />
          </svg>
        </div>
        <div v-if="isTopicCarousel">
          <div class="back-link">
            <router-link :to="{ name: 'Carousel' }"> Accueil </router-link>
          </div>
          <div class="text-2xl mb-4 text-main">
            {{ currentTopic.name }}
          </div>
          <div class="topic-info justify-center my-4">
            <div
              @click="
                $store.dispatch('updateSideText', { text: currentTopic.key })
              "
            >
              Infos
            </div>
            <div v-if="currentTopic.studentDocument !== null">Fiche élève</div>
          </div>
        </div>
        <div v-else>
          <div class="back-link">
            <router-link
              :to="{ name: 'Carousel', query: { topic: currentTopic.key } }"
            >
              {{ currentTopic.name }}
            </router-link>
          </div>
          <div class="text-2xl mb-4 text-main">
            {{ currentSubtopic.name }}
          </div>
          <div class="topic-info justify-center my-4">
            <div
              @click="
                $store.dispatch('updateSideText', {
                  text: `${currentTopic.key}_${currentSubtopic.key}`,
                })
              "
            >
              Infos
            </div>
          </div>
        </div>
        <stack
          v-if="isTopicCarousel"
          :column-min-width="120"
          :gutter-width="8"
          :gutter-height="8"
          monitor-images-loaded
          :style="{ width: '100%' }"
        >
          <stack-item v-for="item in filteredArticles" :key="item.id">
            <router-link
              :to="{
                name: 'ArticlePage',
                params: { article: item.id },
              }"
            >
              <img :src="`${baseUrl}small-images/${item.image}`" />
            </router-link>
          </stack-item>
        </stack>
        <div v-else>
          <router-link
            v-for="item in filteredArticles"
            :key="item.id"
            :to="{
              name: 'ArticlePage',
              params: { article: item.id },
            }"
          >
            <img
              :src="`${baseUrl}small-images/${item.image}`"
              class="w-3/4 mt-4 mx-auto cursor-pointer-custom"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import topicsJson from "@/assets/data/topics.json";
import orderingJson from "@/assets/data/ordering.json";
import { Stack, StackItem } from "vue-stack-grid";

import CarouselTopic from "@/components/CarouselTopic.vue";

function shuffleArray(array) {
  const newArray = [...array];
  let curId = array.length;
  // There remain elements to shuffle
  while (0 !== curId) {
    // Pick a remaining element
    let randId = Math.floor(Math.random() * curId);
    curId -= 1;
    // Swap it with the current element.
    let tmp = newArray[curId];
    newArray[curId] = newArray[randId];
    newArray[randId] = tmp;
  }
  return newArray;
}

export default {
  name: "Carousel",
  components: { CarouselTopic, Stack, StackItem },
  computed: {
    ...mapGetters({
      articlesByTopic: "articles/articlesByTopic",
    }),
    ...mapState({
      articles: (state) => state.articles.articles,
      prevRoute: (state) => state.prevRoute,
      showScrollToTop: (state) => state.showScrollToTop,
    }),
    baseUrl() {
      let url = process.env.BASE_URL;
      if (!url.endsWith("/")) url += "/";
      return url;
    },
    topics() {
      return topicsJson;
    },
    currentTopic() {
      const { topic } = this.$route.query;
      if (!topic) return null;
      return topicsJson.topics.find((t) => t.key === topic);
    },
    currentSubtopic() {
      const { topic, subtopic } = this.$route.query;
      if (!topic || !subtopic) return null;
      return topicsJson.subtopics[topic].find((s) => s.key === subtopic);
    },
    isHomeCarousel() {
      return !this.$route.query.topic;
    },
    isTopicCarousel() {
      return !this.$route.query.subtopic;
    },
    filteredArticles() {
      const { topic, subtopic } = this.$route.query;
      const a = !topic
        ? this.articles
        : this.articles.filter((article) => {
            if (subtopic) {
              return (
                article.topics.includes(topic) &&
                article.subtopics.includes(subtopic)
              );
            }
            return article.topics.includes(topic);
          });
      if (!subtopic) return shuffleArray(a);
      else {
        const order = orderingJson[subtopic];
        a.sort((i1, i2) =>
          order.indexOf(i1.shelf_number) < order.indexOf(i2.shelf_number)
            ? -1
            : 1
        );
        return a;
      }
    },
    rows() {
      return this.isHomeCarousel ? 3 : 2;
      // return this.filteredArticles.length > 6 ? 3 : 1;
    },
    filteredArticlesByRow() {
      const minImgPerRow = Math.floor(this.filteredArticles.length / this.rows);
      const byRow = {};
      let rowLengths = [],
        rowLength = 0,
        x = 0,
        y = 1,
        article = null;
      const maxWidth =
        1000 *
        window.innerWidth *
        0.8 *
        (this.isHomeCarousel ? 3 : this.isTopicCarousel ? 2 : 1);
      for (let i = 0; i < this.filteredArticles.length; i++) {
        if (x >= minImgPerRow || rowLength >= maxWidth) {
          rowLengths.push([rowLength, y]);
          rowLength = 0;
          y++;
          x = 0;
        } else {
          x++;
        }
        article = this.filteredArticles[i];
        (byRow[y] = byRow[y] || []).push(article);
        rowLength += article.width;
      }
      rowLengths.push([rowLength, y]);
      let rowOrders = [];
      // we are allowed to change the order for home/topic
      // carousels => reorder to have the line lengths decrease
      // from top to bottom (= longest line at the top)
      if (this.isHomeCarousel || this.isTopicCarousel) {
        rowLengths.sort((i1, i2) => (i1[0] < i2[0] ? 1 : -1));
        rowOrders = rowLengths.map((l) => l[1]);
      }
      // for subtopic carousel, order is kept as is
      else {
        for (let y = 1; y <= this.rows; y++) rowOrders.push(y);
      }
      return { order: rowOrders, items: byRow };
    },
  },
  methods: {
    setHovering(item) {
      if (item === null) {
        this.$store.dispatch("updateHoveredTopics", { topics: [] });
      } else {
        const topics = item.topics;
        this.$store.dispatch("updateHoveredTopics", { topics });
      }
    },
    goToPreviousPage() {
      if (!this.prevRoute) return;
      this.$router.push({
        path: this.prevRoute.path,
        query: this.prevRoute.query,
        params: this.prevRoute.params,
      });
    },
    handleScroll(event) {
      if (this.isHomeCarousel) return;
      this.$store.dispatch("updateShowScrollToTop", {
        show: event.target.scrollTop > 100,
      });
    },
    scrollToTop() {
      this.$refs.carousel.scrollTo({ top: 0, behavior: "smooth" });
      this.$store.dispatch("updateShowScrollToTop", {
        show: false,
      });
    },
  },
};
</script>

<style scoped>
.back-link {
  font-family: Graphie;
  border-left: 1px solid var(--text-color-main);
  border-right: 1px solid var(--text-color-main);
  color: var(--text-color-main);
  font-size: 15px;
  width: max-content;
  padding: 0 8px;
  margin: 0 auto 4px auto;
}

.scroll-to-top-btn {
  position: fixed;
  bottom: calc(var(--frame-padding) + var(--frame-size) + 12px);
  right: calc(var(--frame-padding) + var(--frame-size) + 12px);
  z-index: 200;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.scroll-to-top-btn.on {
  opacity: 1;
}
</style>
