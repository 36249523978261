var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"absolute top-0 left-0 w-full h-full"},[_c('div',{staticClass:"carousel",class:{ 'carousel-dense': _vm.isHomeCarousel },attrs:{"id":"carousel-horizontal"},on:{"contextmenu":function($event){$event.preventDefault();}}},_vm._l((_vm.filteredArticlesByRow.order),function(row){return _c('div',{key:row,staticClass:"carousel-row"},_vm._l((_vm.filteredArticlesByRow.items[row]),function(item){return _c('div',{key:item.id,staticClass:"carousel-item",class:{ 'carousel-item-overlapping': _vm.isHomeCarousel },on:{"mouseenter":function () { return _vm.setHovering(item); },"mouseleave":function () { return _vm.setHovering(null); }}},[_c('router-link',{attrs:{"to":{
            name: 'ArticlePage',
            params: { article: item.id },
          }}},[_c('img',{staticClass:"cursor-pointer-custom",style:({
              height: ("calc(" + (item.rel_surface) + " * (100vh - 2 * var(--frame-padding) - 2 * var(--frame-size) - 64px - " + (_vm.rows - 1) + " * 16px) / " + _vm.rows + ")"),
            }),attrs:{"src":(_vm.baseUrl + "small-images/" + (item.image))}})])],1)}),0)}),0),_c('div',{ref:"carousel",staticClass:"carousel",class:{ 'carousel-dense': _vm.isHomeCarousel },attrs:{"id":"carousel-vertical"},on:{"contextmenu":function($event){$event.preventDefault();},"scroll":_vm.handleScroll}},[(_vm.isHomeCarousel)?_c('div',{key:"home-carousel"},_vm._l((_vm.topics.topics),function(topic){return _c('CarouselTopic',{key:topic.key,attrs:{"topic":{
          key: topic.key,
          title: topic.name,
          studentDocument: topic.studentDocument,
          count: (_vm.articlesByTopic[topic.key] || []).length,
          subtopics: _vm.topics.subtopics[topic.key],
        }}})}),1):_c('div',{key:"topic-carousel"},[_c('div',{staticClass:"navigation-button scroll-to-top-btn",class:{ on: _vm.showScrollToTop },on:{"click":_vm.scrollToTop}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"d":"M5 15l7-7 7 7"}})])]),(_vm.isTopicCarousel)?_c('div',[_c('div',{staticClass:"back-link"},[_c('router-link',{attrs:{"to":{ name: 'Carousel' }}},[_vm._v(" Accueil ")])],1),_c('div',{staticClass:"text-2xl mb-4 text-main"},[_vm._v(" "+_vm._s(_vm.currentTopic.name)+" ")]),_c('div',{staticClass:"topic-info justify-center my-4"},[_c('div',{on:{"click":function($event){return _vm.$store.dispatch('updateSideText', { text: _vm.currentTopic.key })}}},[_vm._v(" Infos ")]),(_vm.currentTopic.studentDocument !== null)?_c('div',[_vm._v("Fiche élève")]):_vm._e()])]):_c('div',[_c('div',{staticClass:"back-link"},[_c('router-link',{attrs:{"to":{ name: 'Carousel', query: { topic: _vm.currentTopic.key } }}},[_vm._v(" "+_vm._s(_vm.currentTopic.name)+" ")])],1),_c('div',{staticClass:"text-2xl mb-4 text-main"},[_vm._v(" "+_vm._s(_vm.currentSubtopic.name)+" ")]),_c('div',{staticClass:"topic-info justify-center my-4"},[_c('div',{on:{"click":function($event){return _vm.$store.dispatch('updateSideText', {
                text: ((_vm.currentTopic.key) + "_" + (_vm.currentSubtopic.key)),
              })}}},[_vm._v(" Infos ")])])]),(_vm.isTopicCarousel)?_c('stack',{style:({ width: '100%' }),attrs:{"column-min-width":120,"gutter-width":8,"gutter-height":8,"monitor-images-loaded":""}},_vm._l((_vm.filteredArticles),function(item){return _c('stack-item',{key:item.id},[_c('router-link',{attrs:{"to":{
              name: 'ArticlePage',
              params: { article: item.id },
            }}},[_c('img',{attrs:{"src":(_vm.baseUrl + "small-images/" + (item.image))}})])],1)}),1):_c('div',_vm._l((_vm.filteredArticles),function(item){return _c('router-link',{key:item.id,attrs:{"to":{
            name: 'ArticlePage',
            params: { article: item.id },
          }}},[_c('img',{staticClass:"w-3/4 mt-4 mx-auto cursor-pointer-custom",attrs:{"src":(_vm.baseUrl + "small-images/" + (item.image))}})])}),1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }