<template>
  <div
    v-if="article"
    ref="container"
    id="article-container"
    class="w-full h-full relative"
    :class="{
      'container-fullpage': isFullscreen,
      open: openArticleInfo,
    }"
    @contextmenu.prevent
  >
    <div
      ref="imageviewer"
      :id="`image-viewer-${screenSize}`"
      class="m-auto"
      :class="{ fullpage: isFullscreen }"
      :style="{ width: '100%', height: '100%' }"
    />
    <!-- floating action buttons -->
    <div class="floating-action-buttons">
      <!-- exit page -->
      <button
        v-if="!isFullscreen && prevRoute !== null && prevRoute.name !== null"
        id="btn-exit"
        class="navigation-button"
        @click="goToPreviousPage"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <div id="btn-zoom-wrapper">
        <!-- toggle full page -->
        <button
          :id="`btn-full-page-${screenSize}`"
          class="navigation-button btn-full-page"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-width="1"
              :d="
                isFullscreen
                  ? 'M 9,5 V 9 M 9,9 H 5 M 9,9 4,4 m 11,1 v 4 m 0,0 h 4 M 15,9 20,4 M 9,19 v -4 m 0,0 H 5 m 4,0 -5,5 m 11,-5 5,5 m -5,-5 v 4 m 0,-4 h 4'
                  : 'M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4'
              "
            />
          </svg>
        </button>
        <!-- zoom in -->
        <button
          :id="`btn-zoom-in-${screenSize}`"
          class="navigation-button btn-zoom-in"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path stroke-width="1" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </button>
        <!-- zoom out -->
        <button
          :id="`btn-zoom-out-${screenSize}`"
          class="navigation-button btn-zoom-out"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path stroke-width="1" d="M20 12H4" />
          </svg>
        </button>
      </div>
    </div>
    <div
      v-if="!isFullscreen"
      class="close-btn cursor-pointer-custom"
      @click="toggleArticleInfo"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          :d="openArticleInfo ? `M5 15l7-7 7 7` : `M19 9l-7 7-7-7`"
        />
      </svg>
    </div>
    <div
      ref="scroller"
      id="article-info-sm"
      :class="{ open: openArticleInfo }"
      @scroll="handleScroll"
    >
      <div class="article-name">{{ article.name }}</div>
      <div class="article-date">{{ article.date }}</div>
      <div class="article-author">{{ article.author }}</div>
      <div class="article-technique-and-dimensions">
        {{ article.technique }} - {{ article.height.toFixed(0) }} x
        {{ article.width.toFixed(0) }} mm
      </div>
      <div class="article-shelf-number">
        {{ article.shelf_number }}
      </div>
      <div class="divider-h" />
      <ul>
        <li @click="toggleItemSection('context')">
          <div class="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="w-4 h-4 mr-1"
            >
              <path
                :d="
                  openedItemSection === 'context'
                    ? 'M6 18L18 6M6 6l12 12'
                    : 'M12 6v6m0 0v6m0-6h6m-6 0H6'
                "
              />
            </svg>
            <div class="article-section-title">Contexte</div>
          </div>
          <div
            v-if="openedItemSection === 'context'"
            class="article-section-text"
            v-html="article.context"
          />
        </li>
        <li @click="toggleItemSection('description')">
          <div class="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="w-4 h-4 mr-1"
            >
              <path
                :d="
                  openedItemSection === 'description'
                    ? 'M6 18L18 6M6 6l12 12'
                    : 'M12 6v6m0 0v6m0-6h6m-6 0H6'
                "
              />
            </svg>
            <div class="article-section-title">Description</div>
          </div>
          <div
            v-if="openedItemSection === 'description'"
            class="article-section-text"
            v-html="article.description"
          />
        </li>
        <li @click="toggleItemSection('commentary')">
          <div class="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="w-4 h-4 mr-1"
            >
              <path
                :d="
                  openedItemSection === 'commentary'
                    ? 'M6 18L18 6M6 6l12 12'
                    : 'M12 6v6m0 0v6m0-6h6m-6 0H6'
                "
              />
            </svg>
            <div class="article-section-title">Commentaire</div>
          </div>
          <div
            v-if="openedItemSection === 'commentary'"
            class="article-section-text"
            v-html="article.commentary"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OpenSeadragon from "openseadragon";

export default {
  name: "ArticlePage",
  props: {
    screenSize: { type: String, required: true },
  },
  data() {
    return {
      imageViewer: null,
      smIsFullscreen: false,
      mdIsFullscreen: false,
      lgIsFullscreen: false,
      openArticleInfo: false,
      openedItemSection: null,
      canScroll: true,
    };
  },
  mounted() {
    if (!this.imageViewer && this.article) {
      this.setupImageViewer();
    }
  },
  watch: {
    ["$store.state.initialized"](storeIsInitialized) {
      if (storeIsInitialized) this.setupImageViewer();
    },
    screenSize(newScreenSize, oldScreenSize) {
      if (newScreenSize !== oldScreenSize) {
        this.setupImageViewer(true);
      }
    },
  },
  computed: {
    ...mapState({
      articles: (state) => state.articles.articles,
      prevRoute: (state) => state.prevRoute,
    }),
    article() {
      return this.articles.find(
        (article) => article.id == this.$route.params.article
      );
    },
    isFullscreen() {
      return this[`${this.screenSize}IsFullscreen`];
    },
  },
  methods: {
    setupImageViewer(force = false) {
      if (this.imageViewer && !force) return;
      let imgBasePath = process.env.BASE_URL;
      if (!imgBasePath.endsWith("/")) imgBasePath += "/";
      const img = this.article.image.replace(/\.(jpg|jpeg|png)/i, "");
      this.imageViewer = OpenSeadragon({
        id: `image-viewer-${this.screenSize}`,
        showNavigator: false,
        zoomInButton: `btn-zoom-in-${this.screenSize}`,
        zoomOutButton: `btn-zoom-out-${this.screenSize}`,
        tileSources: `${imgBasePath}sliced-images/${img}.dzi`,
      });
      this.imageViewer.gestureSettingsMouse.clickToZoom = false;

      // custom full-page behavior: allows us to
      // keep the navigation buttons
      const $this = this;
      document.getElementById(`btn-full-page-${this.screenSize}`).onclick = (
        event
      ) => {
        event.preventDefault();
        const newFullscreen = !$this[`${this.screenSize}IsFullscreen`];
        $this[`${this.screenSize}IsFullscreen`] = newFullscreen;
        if (this.screenSize === "md")
          $this.$store.dispatch("updateShowMdMenu", { show: newFullscreen });

        // for medium/large displays: true fullscreen
        if (window.innerWidth >= 768) {
          if (OpenSeadragon.isFullScreen()) {
            OpenSeadragon.exitFullScreen();
          } else {
            OpenSeadragon.requestFullScreen(this.$refs.container);
          }
        }
        // for small displays: custom fullscreen (ignore normal behavior)
      };
    },
    goToPreviousPage() {
      if (!this.prevRoute) return;
      this.$router.push({
        path: this.prevRoute.path,
        query: this.prevRoute.query,
        params: this.prevRoute.params,
      });
    },
    toggleItemSection(section) {
      if (section === this.openedItemSection) this.openedItemSection = null;
      else this.openedItemSection = section;
    },
    handleScroll(event) {
      if (!this.canScroll) return;
      const scroll = event.target.scrollTop;
      if (!this.openArticleInfo && scroll > 20) {
        this.openArticleInfo = true;
        // block scrolling during open animation
        this.canScroll = false;
        const $this = this;
        setTimeout(() => {
          $this.canScroll = true;
        }, 750);
      }
    },
    toggleArticleInfo() {
      this.openArticleInfo = !this.openArticleInfo;
      // block scrolling during open/close animation
      this.canScroll = false;
      const $this = this;
      setTimeout(() => {
        $this.canScroll = true;
      }, 750);
      // if closing, then also close any open section and scroll to top
      if (!this.openArticleInfo) {
        this.openedItemSection = null;
        this.$refs.scroller.scroll({ y: 0, behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.articles-page {
  display: grid;
  transition: grid-template-columns 1s ease-in-out;
  grid-template-columns: minmax(200px, 33%) 1fr;
}

#btn-exit {
  position: absolute !important;
  top: var(--img-dislayer-padding);
  right: var(--img-dislayer-padding);
}
#btn-zoom-wrapper {
  display: flex;
  align-items: center;
  position: absolute !important;
  bottom: var(--img-dislayer-padding);
  right: var(--img-dislayer-padding);
}
.btn-full-page {
  margin-right: 8px;
}
.btn-zoom-out {
  border-left: none;
}

.container-fullpage {
  background-color: var(--bg-color-deep);
  box-shadow: 0 0 #0000, 0 0 #0000, inset 0 0 30px 20px rgba(0, 0, 0, 0.2);
}
</style>
