import Vue from "vue";
import VueRouter from "vue-router";
import ArticlePage from "@/views/ArticlePage.vue";
import Carousel from "@/views/Carousel.vue";

import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Carousel",
    component: Carousel,
    meta: {
      prevPage: {
        url: "#",
      },
    },
  },
  {
    path: "/oeuvres/:article",
    name: "ArticlePage",
    component: ArticlePage,
    meta: {
      prevPage: {
        name: "Carousel",
      },
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // close secondary sidebar if it's open
  store.dispatch("updateSideText", { text: null });
  // (on mobile)
  // close mobile phone menu if it's open
  store.dispatch("updateShowSmMenu", { show: false });
  // hide scroll to top
  store.dispatch("updateShowScrollToTop", { show: false });

  // remember previous route
  store.dispatch("updatePrevRoute", { prevRoute: from });

  // apply transition effect
  const transitionOverlays = document.getElementsByClassName(
    "transition-overlay"
  );
  if (transitionOverlays.length === 0) next();
  else {
    transitionOverlays.forEach((transitionOverlay) => {
      transitionOverlay.classList.add("on");
    });
    setTimeout(() => {
      transitionOverlays.forEach((transitionOverlay) => {
        transitionOverlay.classList.remove("on");
      });
    }, 750);
    setTimeout(() => {
      next();
    }, 750);
  }
});

export default router;
