<template>
  <div class="lds-dual-ring"></div>
</template>

<script>
export default {
  name: "Spinner",
};
</script>

<style scoped>
.lds-dual-ring {
  display: inline-block;
  width: 60px;
  height: 60px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 44px;
  height: 44px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid var(--text-color-main);
  border-color: var(--text-color-main) transparent var(--text-color-main)
    transparent;
  animation: lds-dual-ring 1.6s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
