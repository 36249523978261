var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar bg-main relative text-left z-20"},[_c('div',{staticClass:"frame-line-v frame-sidebar-right"}),_c('div',{staticClass:"frame-line-v frame-sidebar-right-inner"}),_c('div',{staticClass:"relative font-medium text-xl text-main cursor-pointer-custom"},[_c('router-link',{attrs:{"to":{ name: 'Carousel' }}},[_c('div',{staticClass:"uppercase sidebar-main-title"},[_vm._v("Napoléon")]),_c('div',{staticClass:"uppercase sidebar-subtitle"},[_vm._v("apologie et satire")])]),_c('div',{staticClass:"absolute bottom-0 left-0 v-spacer border-t-main border-b-main mb-4"})],1),(_vm.article === null)?_c('div',{staticClass:"sidebar-menus"},[_c('div',{staticClass:"sidebar-intro-item cursor-pointer-custom",on:{"click":function () { return _vm.$emit('show-overlay'); }}},[_vm._v(" Introduction par Jean Tulard ")]),_c('div',{staticClass:"divider-h"}),_c('ul',{staticClass:"sidebar-topics-list"},_vm._l((_vm.topics.topics),function(topic){return _c('li',{key:topic.key,staticClass:"sidebar-tree-item sidebar__theme",class:{
          'sidebar-tree-item--active': _vm.$route.query.topic === topic.key,
          'sidebar-tree-item--on': _vm.hoveredTopics.includes(topic.key),
        },on:{"mouseenter":function($event){_vm.hoveredItem = topic.key},"mouseleave":function($event){_vm.hoveredItem = null}}},[_c('div',{staticClass:"inline-flex items-center"},[_c('router-link',{staticClass:"w-full mr-2",attrs:{"to":{ name: 'Carousel', query: { topic: topic.key } }}},[_c('div',{staticClass:"topic-name"},[_vm._v(_vm._s(topic.name))])]),(_vm.hoveredItem === topic.key && topic.studentDocument !== null)?_c('button',{staticClass:"opacity-3/4 hover:opacity-100 focus:outline-none"},[_c('a',{attrs:{"href":topic.studentDocument,"target":"_blank","rel":"noreferrer"}},[_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5","d":"M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"}})])])]):_vm._e(),(_vm.hoveredItem === topic.key)?_c('button',{staticClass:"opacity-3/4 hover:opacity-100 focus:outline-none",on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.openSideText(topic); })($event)}}},[_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5","d":"M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}})])]):_vm._e()],1),_c('ul',{staticClass:"sidebar__subitems",class:{ on: _vm.$route.query.topic === topic.key }},_vm._l((_vm.topics.subtopics[topic.key]),function(subtopic){return _c('li',{key:subtopic.key,staticClass:"sidebar-tree-subitem sidebar__subtheme cursor-pointer-custom",class:{
              'sidebar-tree-subitem--active':
                _vm.$route.query.subtopic === subtopic.key,
            },on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"inline-flex items-center gap-2",on:{"mouseenter":function($event){_vm.hoveredItem = (topic.key) + "_" + (subtopic.key)},"mouseleave":function($event){_vm.hoveredItem = topic.key}}},[_c('router-link',{staticClass:"w-full",attrs:{"to":{
                  name: 'Carousel',
                  query: { topic: topic.key, subtopic: subtopic.key },
                }}},[_c('div',[_vm._v(_vm._s(subtopic.name))])]),(_vm.hoveredItem === ((topic.key) + "_" + (subtopic.key)))?_c('button',{staticClass:"opacity-3/4 hover:opacity-100 focus:outline-none",on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.openSideText(topic, subtopic); })($event)}}},[_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5","d":"M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}})])]):_vm._e()],1)])}),0)])}),0),_c('div',{staticClass:"divider-h"})]):_c('div',{staticClass:"sidebar__item-info"},[_c('div',{staticClass:"sidebar__item-info-top text-main"},[_c('div',{staticClass:"sidebar__article__name"},[_vm._v(_vm._s(_vm.article.name))]),_c('div',{staticClass:"sidebar__article__date"},[_vm._v(_vm._s(_vm.article.date))]),_c('div',{staticClass:"sidebar__article__author"},[_vm._v(_vm._s(_vm.article.author))]),_c('div',{staticClass:"sidebar__article__technique-and-dimensions"},[_vm._v(" "+_vm._s(_vm.article.technique)+" - "+_vm._s(_vm.article.height.toFixed(0))+" x "+_vm._s(_vm.article.width.toFixed(0))+" mm ")]),_c('div',{staticClass:"sidebar__article__shelf-number"},[_vm._v(" "+_vm._s(_vm.article.shelf_number)+" ")])]),_c('div',{staticClass:"divider-h"}),_c('ul',{staticClass:"sidebar__item-sections"},[_c('li',{staticClass:"sidebar-tree-item text-main hover--text-main-light",class:{
          'sidebar-tree-item--active': _vm.activeItemSection === 'context',
        }},[_c('div',{staticClass:"inline-block sidebar__article__section-title cursor-pointer-custom",on:{"click":function () { return _vm.toggleItemSection('context'); }}},[_vm._v(" Contexte ")]),(_vm.activeItemSection === 'context')?_c('div',{staticClass:"sidebar__article__section-text",domProps:{"innerHTML":_vm._s(_vm.article.context)}}):_vm._e()]),_c('li',{staticClass:"sidebar-tree-item text-main hover--text-main-light",class:{
          'sidebar-tree-item--active': _vm.activeItemSection === 'description',
        }},[_c('div',{staticClass:"inline-block sidebar__article__section-title",on:{"click":function () { return _vm.toggleItemSection('description'); }}},[_vm._v(" Description ")]),(_vm.activeItemSection === 'description')?_c('div',{staticClass:"sidebar__article__section-text",domProps:{"innerHTML":_vm._s(_vm.article.description)}}):_vm._e()]),_c('li',{staticClass:"sidebar-tree-item text-main hover--text-main-light",class:{
          'sidebar-tree-item--active': _vm.activeItemSection === 'commentary',
        }},[_c('div',{staticClass:"inline-block sidebar__article__section-title",on:{"click":function () { return _vm.toggleItemSection('commentary'); }}},[_vm._v(" Commentaire ")]),(_vm.activeItemSection === 'commentary')?_c('div',{staticClass:"sidebar__article__section-text",domProps:{"innerHTML":_vm._s(_vm.article.commentary)}}):_vm._e()])])]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"copyright"},[_vm._v(" © Fondation Dosne-Thiers (Institut de France)."),_c('br'),_vm._v(" Collection Frédéric Masson. ")])}]

export { render, staticRenderFns }