<template>
  <div>
    <div class="text-left mb-1 leading-none">
      <router-link
        :to="{ name: 'Carousel', query: { topic: topic.key } }"
        class="text-main text-xl"
      >
        {{ topic.title }}
        <span class="font-graphie text-sm ml-2"> [{{ topic.count }}] </span>
      </router-link>
    </div>
    <div class="topic-info">
      <div @click="$store.dispatch('updateSideText', { text: topic.key })">
        Infos
      </div>
      <router-link
        :to="{
          name: 'Carousel',
          query: { topic: topic.key },
        }"
      >
        Voir tout
      </router-link>
      <div v-if="topic.studentDocument !== null">
        <a :href="topic.studentDocument" target="_blank" rel="noreferrer">
          Fiche élève
        </a>
      </div>
    </div>
    <div class="subtopics-row">
      <div
        v-for="subtopic in topic.subtopics"
        :key="subtopic.key"
        class="subtopic-item"
      >
        <router-link
          v-if="articlesBySubtopic[subtopic.key]"
          :to="{
            name: 'Carousel',
            query: { topic: topic.key, subtopic: subtopic.key },
          }"
        >
          <img
            :src="`${baseUrl}small-images/${
              articlesBySubtopic[subtopic.key][0].image
            }`"
          />
        </router-link>
        <div v-if="articlesBySubtopic[subtopic.key]">
          <div class="subtopic-title">
            {{ subtopic.name }}
            <span class="font-graphie text-sm ml-2">
              [{{ articlesBySubtopic[subtopic.key].length }}]
            </span>
          </div>
          <div class="subtopic-info">
            <div
              @click="
                $store.dispatch('updateSideText', {
                  text: `${topic.key}_${subtopic.key}`,
                })
              "
            >
              Infos
            </div>
            <router-link
              :to="{
                name: 'Carousel',
                query: { topic: topic.key, subtopic: subtopic.key },
              }"
            >
              Voir tout
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CarouselTopic",
  props: {
    topic: { type: Object, required: true },
  },
  computed: {
    ...mapGetters({
      articlesBySubtopic: "articles/articlesBySubtopic",
    }),
    baseUrl() {
      let url = process.env.BASE_URL;
      if (!url.endsWith("/")) url += "/";
      return url;
    },
  },
};
</script>
