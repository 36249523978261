<template>
  <div id="topbar" class="flex justify-between border-b-main bg-main text-main">
    <button
      class="focus:outline-none"
      @click="$store.dispatch('updateShowSmMenu', { show: !showSmMenu })"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          v-if="!showSmMenu"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
        <path v-else stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
    <router-link :to="{ name: 'Carousel' }" class="uppercase">
      <div id="topbar-main-title">Napoléon</div>
      <div id="topbar-subtitle">apologie et satire</div>
    </router-link>
    <a
      class="flex items-center"
      href="https://www.institutdefrance.fr/lesdossiers/napoleon-apologie-et-satire-images-dun-regne/"
      target="_blank"
      rel="noopener"
    >
      <IDFLogo :height="38" :width="32" />
    </a>
    <div v-if="showSmMenu" id="topbar-menu">
      <div @click="() => $emit('show-overlay')">
        Introduction par Jean Tulard
      </div>
      <div class="divider-h"></div>
      <div class="fragment">
        <div v-for="topic in topics.topics" :key="topic.key">
          <div @click="goTo({ name: 'Carousel', query: { topic: topic.key } })">
            {{ topic.name }}
          </div>
          <ul>
            <li
              v-for="subtopic in topics.subtopics[topic.key]"
              :key="subtopic.key"
              class="text-additional"
            >
              <div
                @click="
                  goTo({
                    name: 'Carousel',
                    query: { topic: topic.key, subtopic: subtopic.key },
                  })
                "
              >
                {{ subtopic.name }}
              </div>
            </li>
          </ul>
          <div class="divider-h"></div>
        </div>
      </div>
      <div>
        <a
          class="flex items-center"
          href="https://www.institutdefrance.fr/lesdossiers/napoleon-apologie-et-satire-images-dun-regne/"
          target="_blank"
          rel="noopener"
        >
          Retour vers le site de l'Institut de France
        </a>
      </div>
      <div class="divider-h"></div>
      <div class="copyright">
        © Fondation Dosne-Thiers (Institut de France). Collection Frédéric
        Masson.
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import IDFLogo from "@/components/IDFLogo.vue";
import topicsJson from "@/assets/data/topics.json";

export default {
  name: "Topbar",
  components: { IDFLogo },
  computed: {
    ...mapState({
      showSmMenu: (state) => state.showSmMenu,
    }),
    topics() {
      return topicsJson;
    },
  },
  methods: {
    goTo(route) {
      this.$store.dispatch("updateShowSmMenu", { show: false });
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
#topbar {
  padding: 4px calc(16px + var(--frame-size) * 2) 4px
    calc(16px + var(--frame-size));
  margin: 0 calc(-1 * var(--frame-size));
  height: var(--topbar-height);
}

#topbar-main-title {
  font-size: 6vw;
  margin-bottom: -2vw;
}

#topbar-subtitle {
  font-size: 3.25vw;
}
</style>
