var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-left mb-1 leading-none"},[_c('router-link',{staticClass:"text-main text-xl",attrs:{"to":{ name: 'Carousel', query: { topic: _vm.topic.key } }}},[_vm._v(" "+_vm._s(_vm.topic.title)+" "),_c('span',{staticClass:"font-graphie text-sm ml-2"},[_vm._v(" ["+_vm._s(_vm.topic.count)+"] ")])])],1),_c('div',{staticClass:"topic-info"},[_c('div',{on:{"click":function($event){return _vm.$store.dispatch('updateSideText', { text: _vm.topic.key })}}},[_vm._v(" Infos ")]),_c('router-link',{attrs:{"to":{
        name: 'Carousel',
        query: { topic: _vm.topic.key },
      }}},[_vm._v(" Voir tout ")]),(_vm.topic.studentDocument !== null)?_c('div',[_c('a',{attrs:{"href":_vm.topic.studentDocument,"target":"_blank","rel":"noreferrer"}},[_vm._v(" Fiche élève ")])]):_vm._e()],1),_c('div',{staticClass:"subtopics-row"},_vm._l((_vm.topic.subtopics),function(subtopic){return _c('div',{key:subtopic.key,staticClass:"subtopic-item"},[(_vm.articlesBySubtopic[subtopic.key])?_c('router-link',{attrs:{"to":{
          name: 'Carousel',
          query: { topic: _vm.topic.key, subtopic: subtopic.key },
        }}},[_c('img',{attrs:{"src":(_vm.baseUrl + "small-images/" + (_vm.articlesBySubtopic[subtopic.key][0].image))}})]):_vm._e(),(_vm.articlesBySubtopic[subtopic.key])?_c('div',[_c('div',{staticClass:"subtopic-title"},[_vm._v(" "+_vm._s(subtopic.name)+" "),_c('span',{staticClass:"font-graphie text-sm ml-2"},[_vm._v(" ["+_vm._s(_vm.articlesBySubtopic[subtopic.key].length)+"] ")])]),_c('div',{staticClass:"subtopic-info"},[_c('div',{on:{"click":function($event){return _vm.$store.dispatch('updateSideText', {
                text: ((_vm.topic.key) + "_" + (subtopic.key)),
              })}}},[_vm._v(" Infos ")]),_c('router-link',{attrs:{"to":{
              name: 'Carousel',
              query: { topic: _vm.topic.key, subtopic: subtopic.key },
            }}},[_vm._v(" Voir tout ")])],1)]):_vm._e()],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }