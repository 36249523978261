import topics from "@/assets/data/topics.json";

const state = () => ({
  topics,
  articles: [],
});

const getters = {
  getArticleById: (state) => (id) => {
    return state.articles.find((a) => a.id === id);
  },
  articlesByTopic(state) {
    if (state.articles.length === 0) return {};
    return state.articles.reduce((acc, article) => {
      for (const topic of article.topics) {
        (acc[topic] = acc[topic] || []).push(article);
      }
      return acc;
    }, {});
  },
  articlesBySubtopic(state) {
    if (state.articles.length === 0) return {};
    return state.articles.reduce((acc, article) => {
      for (const subtopic of article.subtopics) {
        (acc[subtopic] = acc[subtopic] || []).push(article);
      }
      return acc;
    }, {});
  },
};

const mutations = {
  setArticles(state, { articles }) {
    state.articles = articles;
  },
  pushArticle(state, { article }) {
    state.articles.push(article);
  },
  setArticle(state, { uid, data }) {
    const idx = state.articles.findIndex((article) => article.uid === uid);
    state.articles.splice(idx, 1, data);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
