<template>
  <div>
    <div id="intro-sm" :class="{ off: !on }">
      <div
        id="intro-bg"
        :style="{
          'background-image': `url(${imgBasePath}napoleon-fond-mobile.png)`,
        }"
      />
      <IDFLogo :height="76" :width="64" class="intro-logo" />
      <div id="intro-text">
        <div id="intro-main-title">Napoléon</div>
        <div id="intro-small-title">apologie et satire</div>
        <div id="intro-subtitle">images d'un règne</div>
        <Spinner class="mt-4" />
      </div>
    </div>
    <div id="intro-md" :class="{ off: !on }">
      <div
        id="intro-bg"
        :style="{
          'background-image': `url(${imgBasePath}napoleon-fond-accueil.png)`,
        }"
      />
      <div id="intro-text">
        <IDFLogo :height="76" :width="64" class="mb-4 mx-auto" />
        <div id="intro-main-title">Napoléon, apologie et satire</div>
        <div id="intro-subtitle">images d'un règne</div>
        <Spinner class="mt-4" />
      </div>
    </div>
  </div>
</template>

<script>
import IDFLogo from "@/components/IDFLogo.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "Intro",
  components: { IDFLogo, Spinner },
  props: {
    on: { type: Boolean, default: true },
  },
  computed: {
    imgBasePath() {
      let p = process.env.BASE_URL;
      if (!p.endsWith("/")) p += "/";
      return p;
    },
  },
};
</script>

<style scoped>
#intro-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 0;
}

#intro-text {
  z-index: 1;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
