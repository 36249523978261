<template>
  <div id="overlay">
    <div id="overlay-content">
      <div id="overlay-main-title">
        {{ intro.title }}
      </div>
      <div id="overlay-subtitle">
        {{ intro.subtitle }}
      </div>
      <div id="overlay-text" class="font-graphie" v-html="intro.content"></div>
      <div id="overlay-author" class="text-sm font-graphie">
        Jean Tulard, membre de l'Institut
      </div>
      <div id="overlay-button">
        <button
          class="cursor-pointer-custom text-main hover--text-main-light focus:outline-none w-12 h-12 border-text rounded-full p-2"
          @click="$emit('close')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-width="1" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import texts from "@/assets/data/texts.json";

export default {
  name: "Overlay",
  props: {
    on: { type: Boolean, default: true },
  },
  computed: {
    intro() {
      return texts.intro;
    },
  },
};
</script>
