<template>
  <div
    class="secondary-sidebar py-4 text-left"
    :class="{ open: sideText !== null }"
  >
    <div class="absolute w-full h-full top-0 left-0"></div>
    <div
      id="close-btn"
      class="navigation-button absolute cursor-pointer-custom"
      @click="
        () => {
          $store.dispatch('updateSideText', { text: null });
        }
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path stroke-width="1" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>
    <div v-if="sideText !== null" class="text-main">
      <div class="secondary-sidebar__title">{{ texts[sideText].title }}</div>
      <div class="secondary-sidebar__subtitle">
        {{ texts[sideText].subtitle }}
      </div>
      <div
        class="secondary-sidebar__text"
        v-html="texts[sideText].content"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import texts from "@/assets/data/texts.json";

export default {
  name: "SecondarySidebar",
  computed: {
    ...mapState({
      sideText: (state) => state.sideText,
    }),
    texts() {
      return texts;
    },
  },
};
</script>

<style scoped>
.secondary-sidebar {
  position: absolute;
  padding-left: 24px;
  padding-right: 28px;
  top: 0;
  transition: all 0.75s ease-in-out;
  z-index: 10;
  box-shadow: 0 0 #0000, 0 0 #0000, inset 0 0 30px 20px rgba(0, 0, 0, 0.1);
}

.secondary-sidebar.open {
  transform: translateX(0%) !important;
}
</style>
