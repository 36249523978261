import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import articles from "./modules/articles.js";

export default new Vuex.Store({
  state: () => ({
    initialized: false,
    hoveredTopics: [],
    sideText: null,
    prevRoute: null,
    showMdMenu: false,
    showSmMenu: false,
    showScrollToTop: false,
  }),
  mutations: {
    setInitialized(state, { initialized }) {
      state.initialized = initialized;
    },
    setSideText(state, { text }) {
      state.sideText = text;
    },
    setHoveredTopics(state, { topics }) {
      state.hoveredTopics = topics;
    },
    setPrevRoute(state, { prevRoute }) {
      state.prevRoute = prevRoute;
    },
    setShowMdMenu(state, { show }) {
      state.showMdMenu = show;
    },
    setShowSmMenu(state, { show }) {
      state.showSmMenu = show;
    },
    setShowScrollToTop(state, { show }) {
      state.showScrollToTop = show;
    },
  },
  actions: {
    updateInitialized({ commit }, { initialized }) {
      commit("setInitialized", { initialized });
    },
    updateSideText({ commit }, { text }) {
      commit("setSideText", { text });
    },
    updateHoveredTopics({ commit }, { topics }) {
      commit("setHoveredTopics", { topics });
    },
    updatePrevRoute({ commit }, { prevRoute }) {
      commit("setPrevRoute", { prevRoute });
    },
    updateShowMdMenu({ commit }, { show }) {
      commit("setShowMdMenu", { show });
    },
    updateShowSmMenu({ commit }, { show }) {
      commit("setShowSmMenu", { show });
    },
    updateShowScrollToTop({ commit }, { show }) {
      commit("setShowScrollToTop", { show });
    },
  },
  modules: {
    articles,
  },
});
