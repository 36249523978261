<template>
  <div class="sidebar bg-main relative text-left z-20">
    <div class="frame-line-v frame-sidebar-right"></div>
    <div class="frame-line-v frame-sidebar-right-inner"></div>
    <div class="relative font-medium text-xl text-main cursor-pointer-custom">
      <router-link :to="{ name: 'Carousel' }">
        <div class="uppercase sidebar-main-title">Napoléon</div>
        <div class="uppercase sidebar-subtitle">apologie et satire</div>
      </router-link>
      <div
        class="absolute bottom-0 left-0 v-spacer border-t-main border-b-main mb-4"
      ></div>
    </div>
    <div v-if="article === null" class="sidebar-menus">
      <div
        class="sidebar-intro-item cursor-pointer-custom"
        @click="() => $emit('show-overlay')"
      >
        Introduction par Jean Tulard
      </div>
      <div class="divider-h" />
      <ul class="sidebar-topics-list">
        <li
          v-for="topic in topics.topics"
          :key="topic.key"
          class="sidebar-tree-item sidebar__theme"
          :class="{
            'sidebar-tree-item--active': $route.query.topic === topic.key,
            'sidebar-tree-item--on': hoveredTopics.includes(topic.key),
          }"
          @mouseenter="hoveredItem = topic.key"
          @mouseleave="hoveredItem = null"
        >
          <div class="inline-flex items-center">
            <router-link
              :to="{ name: 'Carousel', query: { topic: topic.key } }"
              class="w-full mr-2"
            >
              <div class="topic-name">{{ topic.name }}</div>
            </router-link>
            <button
              v-if="hoveredItem === topic.key && topic.studentDocument !== null"
              class="opacity-3/4 hover:opacity-100 focus:outline-none"
            >
              <a :href="topic.studentDocument" target="_blank" rel="noreferrer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
              </a>
            </button>
            <button
              v-if="hoveredItem === topic.key"
              class="opacity-3/4 hover:opacity-100 focus:outline-none"
              @click.stop="() => openSideText(topic)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
          </div>
          <ul
            class="sidebar__subitems"
            :class="{ on: $route.query.topic === topic.key }"
          >
            <li
              v-for="subtopic in topics.subtopics[topic.key]"
              :key="subtopic.key"
              class="sidebar-tree-subitem sidebar__subtheme cursor-pointer-custom"
              :class="{
                'sidebar-tree-subitem--active':
                  $route.query.subtopic === subtopic.key,
              }"
              @click.stop
            >
              <div
                class="inline-flex items-center gap-2"
                @mouseenter="hoveredItem = `${topic.key}_${subtopic.key}`"
                @mouseleave="hoveredItem = topic.key"
              >
                <router-link
                  :to="{
                    name: 'Carousel',
                    query: { topic: topic.key, subtopic: subtopic.key },
                  }"
                  class="w-full"
                >
                  <div>{{ subtopic.name }}</div>
                </router-link>
                <button
                  v-if="hoveredItem === `${topic.key}_${subtopic.key}`"
                  class="opacity-3/4 hover:opacity-100 focus:outline-none"
                  @click.stop="() => openSideText(topic, subtopic)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <div class="divider-h" />
    </div>
    <div v-else class="sidebar__item-info">
      <div class="sidebar__item-info-top text-main">
        <div class="sidebar__article__name">{{ article.name }}</div>
        <div class="sidebar__article__date">{{ article.date }}</div>
        <div class="sidebar__article__author">{{ article.author }}</div>
        <div class="sidebar__article__technique-and-dimensions">
          {{ article.technique }} - {{ article.height.toFixed(0) }} x
          {{ article.width.toFixed(0) }} mm
        </div>
        <div class="sidebar__article__shelf-number">
          {{ article.shelf_number }}
        </div>
      </div>
      <div class="divider-h" />
      <ul class="sidebar__item-sections">
        <li
          class="sidebar-tree-item text-main hover--text-main-light"
          :class="{
            'sidebar-tree-item--active': activeItemSection === 'context',
          }"
        >
          <div
            class="inline-block sidebar__article__section-title cursor-pointer-custom"
            @click="() => toggleItemSection('context')"
          >
            Contexte
          </div>
          <div
            v-if="activeItemSection === 'context'"
            class="sidebar__article__section-text"
            v-html="article.context"
          />
        </li>
        <li
          class="sidebar-tree-item text-main hover--text-main-light"
          :class="{
            'sidebar-tree-item--active': activeItemSection === 'description',
          }"
        >
          <div
            class="inline-block sidebar__article__section-title"
            @click="() => toggleItemSection('description')"
          >
            Description
          </div>
          <div
            v-if="activeItemSection === 'description'"
            class="sidebar__article__section-text"
            v-html="article.description"
          />
        </li>
        <li
          class="sidebar-tree-item text-main hover--text-main-light"
          :class="{
            'sidebar-tree-item--active': activeItemSection === 'commentary',
          }"
        >
          <div
            class="inline-block sidebar__article__section-title"
            @click="() => toggleItemSection('commentary')"
          >
            Commentaire
          </div>
          <div
            v-if="activeItemSection === 'commentary'"
            class="sidebar__article__section-text"
            v-html="article.commentary"
          />
        </li>
      </ul>
    </div>
    <div class="copyright">
      © Fondation Dosne-Thiers (Institut de France).<br />
      Collection Frédéric Masson.
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import topicsJson from "@/assets/data/topics.json";

export default {
  name: "MainSidebar",
  data() {
    return {
      hoveredItem: null,
      activeItemSection: null,
    };
  },
  watch: {
    $route() {
      this.activeItemSection = null;
    },
  },
  computed: {
    ...mapGetters({
      getArticleById: "articles/getArticleById",
    }),
    ...mapState({
      hoveredTopics: (state) => state.hoveredTopics,
    }),
    topics() {
      return topicsJson;
    },
    article() {
      const articleId = this.$route.params.article;
      if (!articleId) return null;
      return this.getArticleById(articleId);
    },
  },
  methods: {
    toggleItemSection(section) {
      if (this.activeItemSection === section) this.activeItemSection = null;
      else this.activeItemSection = section;
    },
    openSideText(topic, subtopic = null) {
      const key = subtopic ? `${topic.key}_${subtopic.key}` : topic.key;
      this.$store.dispatch("updateSideText", { text: key });
    },
  },
};
</script>

<style scoped>
.sidebar {
  width: calc(100% + calc(2 * var(--frame-size)));
  margin: calc(-1 * var(--frame-size));
  padding: var(--frame-size);
  height: calc(100vh - 31px);
  overflow-y: hidden;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: auto 1fr 48px;
  position: relative;
}

.sidebar-menus {
  margin-left: calc(-1 * var(--frame-size));
  margin-top: var(--frame-size);
}
.sidebar-intro-item {
  margin-left: calc(var(--frame-size) + 1px);
  color: var(--text-color-inactive);
  font-size: 18px;
  padding: 16px 0;
}
.sidebar-intro-item:hover {
  color: var(--text-color-active);
}
.sidebar-topics-list {
  padding: 16px 12px 18px 0;
}
.sidebar-tree-item {
  padding: 2px var(--frame-size) 2px calc(var(--frame-size) + 1px);
  color: var(--text-color-inactive);
}
.sidebar-tree-item:hover,
.sidebar-tree-item--active,
.sidebar-tree-item--on {
  color: var(--text-color-active);
}
.sidebar-tree-item::before {
  content: "+";
  display: inline-block;
  margin-left: calc(-1 * var(--frame-size));
  font-size: 0.8em;
  font-family: Graphie;
  transition: all 0.5s linear;
  transform: rotate(0deg);
}
.sidebar-tree-item.sidebar-tree-item--active::before {
  transform: rotate(45deg);
}

.sidebar-tree-subitem {
  padding: 0;
  color: var(--text-color-inactive);
  transition: all 0.5s ease-in-out;
}
.sidebar-tree-subitem:hover,
.sidebar-tree-subitem--active {
  padding-left: 8px;
  color: var(--text-color-active);
}
.sidebar-tree-subitem > div {
  height: 30px;
  line-height: 1;
}
.sidebar__theme {
  font-size: 18px;
}
.sidebar__subtheme {
  font-size: 15px;
}
.sidebar__subitems {
  max-height: 0;
  transition: max-height 0.75s ease-in-out;
  overflow-y: hidden;
}
.sidebar__subitems.on {
  max-height: 100vh;
  margin-bottom: 8px;
}

.sidebar__item-info {
  margin-top: var(--frame-size);
  margin-left: calc(-1 * var(--frame-size));
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sidebar__item-info::-webkit-scrollbar {
  width: var(--frame-size);
}

.sidebar__item-info::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar__item-info::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 8px;
}

.sidebar__item-info-top {
  padding: 16px var(--frame-size);
  cursor: text;
}
.sidebar__item-sections {
  padding-top: 16px;
  scrollbar-width: thin;
  scrollbar-color: var(--border-color) transparent;
}
.sidebar__item-sections .sidebar-tree-item {
  padding-right: 8px;
}

.sidebar__article__name {
  font-size: 22px;
  line-height: 20px;
  margin-bottom: 8px;
}
.sidebar__article__date {
  font-size: 17px;
}
.sidebar__article__author {
  font-size: 16px;
  line-height: 1.2;
  margin: 4px 0;
}
.sidebar__article__technique-and-dimensions {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 4px;
  margin-top: 4px;
  color: var(--text-color-additional);
}
.sidebar__article__shelf-number {
  font-size: 12px;
  color: var(--text-color-additional);
}
.sidebar__article__section-title {
  font-size: 20px;
}
.sidebar__article__section-text {
  cursor: text;
  font-family: Graphie;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 8px;
}

.topic-name {
  line-height: 1.2;
}
</style>
